import React from "react";
import { Link } from "react-router-dom";
import Cursor from "./Cursor";

function NotFound() {
    return (
        <>
        <Cursor />
        <div className="container" >
            <div className="position-relative text-center mt-5 z-1" >
                <h1 className="display-3" >404</h1>
                <hr />
                <h3 className="display-5">This page could not be found.</h3>
                <Link className="btn btn-lg btn-outline-info mt-3 mb-3" to="/">Home</Link>
            </div>
        </div>
        </>
    )
}

export default NotFound