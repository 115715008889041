import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import "./Cursor.css"

function Cursor() {
    const [mousePosition, setMousePosition] = useState({
        x: 0,
        y: 0
    })
    const variants = {
        default: {
            x: mousePosition.x - 512,
            y: mousePosition.y - 500,
        }
    }

    useEffect(() => {
        const handleMouseMove = (e) => {
            setMousePosition({
                x: e.clientX,
                y: e.clientY
            })
        }

        window.addEventListener("mousemove", handleMouseMove)

        return () => {
            window.removeEventListener("mousemove", handleMouseMove)
        }
    }, [])

    return (
        <motion.div
        className="cursor z-0"
        variants={variants}
        animate="default"
        transition={{ duration: 0, }}
        />
    )
}

export default Cursor