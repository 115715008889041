import React from "react";
import "./PortfolioCard.css";

function PortfolioCard({ project }) {
    return (
        <>
            <li>
                <a
                    className="card-title link-underline link-underline-opacity-0"
                    href={project.link}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <div className="card w-100 mt-3 portfolio-card">
                        <div className="row g-0">
                            <div className="col-md-4 d-flex align-items-center">
                                <img
                                    src={project.img}
                                    className="img-fluid ms-md-1 rounded-2 "
                                    alt={project.imgAlt}
                                />
                            </div>
                            <div className="col-md-8">
                                <div
                                    className="card-body"
                                    style={{
                                        backgroundColor: "RGBa(0, 0, 0, 0",
                                    }}
                                >
                                    <h5 className="icon-link icon-link-hover">
                                        {project.title}
                                        <span
                                            className="bi bi-arrow-up-right custom-icon"
                                            style={{
                                                fontSize: ".8rem",
                                                "--bs-icon-link-transform":
                                                    "translate3d(.125rem, -.125rem, 0)",
                                            }}
                                        ></span>
                                    </h5>
                                    <h6 className="card-subtitle mb-2 text-body-secondary">
                                        {project.subtitle}
                                    </h6>
                                    <p className="card-text">
                                        {project.description}{" "}
                                        <span className="text-body-secondary">
                                            {project.functionality}
                                        </span>
                                    </p>
                                    <div className="d-flex flex-row flex-wrap">
                                        {project.tech.map((item) => (
                                            <React.Fragment
                                                key={project.tech.indexOf(item)}
                                            >
                                                <div
                                                    className="d-flex align-items-center w-auto m-1 px-2 py-1 justify-content-center rounded-pill"
                                                    style={{
                                                        color: "#5ee0d4",
                                                        backgroundColor:
                                                            "RGBa(45, 212, 191, .1)",
                                                        whiteSpace: "nowrap",
                                                        overflow: "hidden",
                                                        textOverflow:
                                                            "ellipsis",
                                                        maxWidth: "100%",
                                                    }}
                                                >
                                                    {item}
                                                </div>
                                            </React.Fragment>
                                        ))}
                                    </div>
                                    {/*<p className="card-subtitle my-2 text-body-secondary">{project.tech.join(", ")}</p>*/}
                                </div>
                            </div>
                        </div>
                    </div>
                </a>
            </li>
        </>
    );
}

export default PortfolioCard;
