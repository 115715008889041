import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import "./Home.css";
import PortfolioList from "./PortfolioList";
import Menu from "./Menu";
import Cursor from "../utils/Cursor";

function Home() {
    const [aboutExpanded, setAboutExpanded] = useState(false)
    const projectsRef = useRef(null)

    useEffect(() => {
        if (!aboutExpanded) {
            setTimeout(() => {
                projectsRef.current.scrollIntoView({ behavior: "smooth", block: "start" })
            }, 350)
        }
    }, [aboutExpanded, projectsRef])

    function toggleExpanded() {
        aboutExpanded ? setAboutExpanded(false) : setAboutExpanded(true)
    }

    return (
        <>
            <Cursor />
            <div className="container-fluid">
                <div className="row h-100 z-1 position-relative me-md-5">
                    <div className="col-md-6 side-bar sticky-md-top h-100 text-center text-md-start">
                        <Menu />
                    </div>
                    <div className="col mt w-100">
                        <h6 id="about-me" className="display-6 mt-5 pt-3">
                            About Me
                        </h6>
                        <div className="row gx-0 px-auto">
                            <p className="lead">
                                I am an entry level software developer with a
                                background in highly technical, precision
                                manufacturing, spending nearly a decade
                                machining components for devices being sent to
                                space, used in life-saving medical technology,
                                and everything in between. My experience working
                                with high-stakes technical projects, where a
                                minor error can mean costly downtime, has
                                fostered a deep commitment to quality and
                                efficiency in everything I do. The same
                                precision and critical thinking that I have used
                                daily to troubleshoot machinery, material
                                properties, programming intricacies, and even
                                environmental factors like temperature now apply
                                to debugging and refining code.
                            </p>
                            <div
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseExample"
                                aria-expanded="false"
                                aria-controls="collapseExample"
                                onClick={() => toggleExpanded()}
                            >
                                {aboutExpanded ? null : (
                                    <button
                                        className="text-primary btn btn-link link-underline link-underline-opacity-0 p-0"
                                        aria-expanded="true"
                                    >
                                        Read More
                                    </button>
                                )}
                            </div>
                            <div className="collapse" id="collapseExample">
                                <p className="lead">
                                    Transitioning into software, I realized my
                                    ability to analyze and solve technical
                                    challenges transfer naturally into writing
                                    clean, efficient code, optimizing
                                    performance, and collaborating with teams to
                                    deliver solutions that work. Just as in
                                    machining, where every adjustment to any one
                                    of multiple variables matters, I approach
                                    software development with a mindset that
                                    combines meticulous attention to detail with
                                    a passion for continuous learning and
                                    improvement. Having experienced firsthand
                                    the frustration of using software that
                                    didn't perform as expected, I'm driven by an
                                    intrinsic desire to rigorously test my code,
                                    ensuring it works flawlessly.
                                </p>
                                <p className="lead">
                                    Before I found my way into high-tech
                                    manufacturing, I spent my early twenties
                                    exploring a wide range of jobs—everything
                                    from delivering pizzas and working in
                                    warehouses to agricultural management
                                    consulting and even co-founding a topical
                                    product startup. These diverse experiences
                                    taught me invaluable lessons about hard
                                    work, adaptability, and resourcefulness.
                                    Each job, whether it was moving furniture
                                    for Craigslist gigs or managing crops, honed
                                    my ability to problem-solve in unpredictable
                                    situations and connect with people from all
                                    walks of life. These experiences instilled
                                    in me a deep understanding of the value of
                                    grit, collaboration, and keeping an open
                                    mind - qualities I now bring to every coding
                                    project and technical challenge I tackle.
                                </p>
                                <p className="lead">
                                    Outside of work, I'm passionate about giving
                                    back and staying active. I've always been
                                    driven by a sense of community - whether
                                    distributing essentials through my mom's
                                    ministry as a teen or helping those in need
                                    around Santa Cruz during the pandemic.
                                    Otherwise, I find fulfillment by being out
                                    on a dirt bike, hiking with my dog, or
                                    foraging for wild mushrooms in the redwoods.
                                    I also enjoy making art, dancing at live
                                    shows, playing music, and cooking for
                                    friends, while staying intellectually
                                    curious through reading and self-guided
                                    learning - from having been homeschooled to
                                    my recent coding boot camp, I've always
                                    taken an independent approach to education,
                                    constantly seeking new challenges.
                                </p>
                                <div
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseExample"
                                    aria-expanded="false"
                                    aria-controls="collapseExample"
                                    onClick={() => toggleExpanded()}
                                >
                                    <button
                                        className="text-primary btn btn-link link-underline link-underline-opacity-0 p-0"
                                        aria-expanded="true"
                                    >
                                        Read Less
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div
                            ref={projectsRef}
                            id="projects"
                            className="row gx-0 px-auto mt-5 mt-md-3 pt-md-3 w-100"
                        >
                            <h6 className="display-6">Projects</h6>
                            <PortfolioList />
                        </div>
                        <div id="resume" className="ms-1 align-self-start">
                            <h5>
                                <Link
                                    className="custom-icon icon-link icon-link-hover link-underline link-underline-opacity-0 mt-3 mb-3"
                                    to="/resume"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    View Full R&eacute;sum&eacute;
                                    <span
                                        className="bi bi-arrow-up-right"
                                        style={{
                                            fontSize: ".8rem",
                                            "--bs-icon-link-transform":
                                                "translate3d(.125rem, -.125rem, 0)",
                                        }}
                                    ></span>
                                </Link>
                            </h5>
                        </div>
                        <div className="mt-2">
                            <p className="text-secondary">
                                <small>
                                    Coded in Visual Studio Code, built with
                                    React, and styled with Bootstrap. Hosted on
                                    Render.
                                </small>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Home;
