import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

function Menu() {
    const [width, setWidth] = useState(window.innerWidth)
    const breakpoint = 768

    useEffect(() => {
        const handleResizeWindow = () => setWidth(window.innerWidth)

        // subscribe to window resize event "onComponentDidMount"
        window.addEventListener("resize", handleResizeWindow)
        return () => {
        // unsubscribe "onComponentDestroy"
         window.removeEventListener("resize", handleResizeWindow);
        }
    }, [])

    return (
        <header className="container mt-5">
            <div className="ms-md-5">
                <h1 className="display-3">James Lycett</h1>
                <h2 className="text-body-secondary">Full-Stack Engineer</h2>
            </div>
            <div className="ms-md-3">
                {width < breakpoint ? 
                null :
                <nav className="nav flex-column mt-5 mb-5 ms-2">
                    <ul className="mt-5" style={{listStyleType: "none"}}>
                        <li className="nav-item">
                            <a
                                className="link-underline link-underline-opacity-0 icon-link icon-link-hover"
                                href="#about-me"
                                role="button"
                                style={{ fontSize: "1.25rem" }}
                                >
                                    About Me
                                    <span 
                                        className="bi bi-arrow-right" 
                                        style={{ fontSize: "1rem", marginBottom: ".35rem"}}
                                        >
                                    </span>
                            </a>
                        </li>
                        <li className="nav-item mt-2">
                            <a 
                                className="link-underline link-underline-opacity-0 icon-link icon-link-hover mt-3"
                                href="#projects"
                                role="button"
                                style={{ fontSize: "1.25rem" }}
                                >
                                    Projects
                                    <span 
                                        className="bi bi-arrow-right"
                                        style={{ fontSize: "1rem", marginBottom: ".35rem"}}
                                        >
                                    </span>
                            </a>
                        </li>
                        <li className="nav-item mt-2">
                            <Link
                                className="link-underline link-underline-opacity-0 icon-link icon-link-hover mt-3"
                                to="/resume"
                                style={{ fontSize: "1.25rem" }}
                                target="_blank" 
                                rel="noopener noreferrer"
                                >
                                    R&eacute;sum&eacute;
                                    <span 
                                        className="bi bi-arrow-right"
                                        style={{ fontSize: "1rem", marginBottom: ".35rem"}}
                                        >
                                    </span>
                            </Link>
                        </li>
                    </ul>
                </nav>
                }
                <ul className="btn-group mt-5" style={{ listStyleType: "none", marginLeft: width < breakpoint ? -40 : 0 }}>
                    <li>
                        <a className="btn btn-outline-info ms-md-0 ms-2" href="https://github.com/James-Lycett" target="_blank" rel="noopener noreferrer"><i class="bi bi-github"></i> Github</a>
                    </li>
                    <li>
                        <a className="btn btn-outline-info ms-4" href="https://www.linkedin.com/in/james-lycett/" target="_blank" rel="noopener noreferrer"><i class="bi bi-linkedin"></i> Linkedin</a>
                    </li>
                    <li>
                        <a className="btn btn-outline-info ms-4" href="https://www.upwork.com/freelancers/jameslycett" target="_blank" rel="noopener noreferrer"><i class="bi bi-person-workspace"></i> Upwork</a>
                    </li>
                </ul> 
            </div>
        </header>
    )
}

export default Menu