import React from "react";
import { portfolio } from "../Resources/portfolio"
import PortfolioCard from "./PortfolioCard";

function PortfolioList() {
    return (
        <ul className="p-0" style={{ listStyleType: "none", padding: 0 }}>
            {portfolio.map((project) => (
                <React.Fragment key={project.id}>
                    <PortfolioCard project={project}/>
                </React.Fragment>
            ))}
        </ul>
    )
}

export default PortfolioList