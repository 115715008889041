import React from "react";
import resumePDF from "../Resources/james_lycett_resume.pdf"

function Resume() {
    return (
        <div>
        <iframe
          title="Resume PDF"
          width="100%"
          height="600px"
          src={resumePDF}
        ></iframe>
      </div>
    )
}

export default Resume