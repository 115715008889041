/*

    {
        id: <(number) a unique id for placement in ul>,
        title: <(string) common name for project >,
        subtitle: <(string) a brief summary of what the project is>,
        description: <(string) describe the functionality of the site or just whatever else you wanna emphasize>,
        functionality: <(string) either [Demo] or [Live] - meaning whether this is an actual thing or if it's just to show off>,
        link: <(string) a url to link to the project>,
        githubLink: <(string) a url to link to the project public codebase. currently unused>,
        tech: <(array of strings) tech stack used to make the project. currently unused>,
        img: <(variable assigned to jpg via import) usually just a screenshot of the project homepage, or maybe something a little mre explanatory if necessary>,
        imgAlt: <(string) I don't think SEO is important here, probably just something like 'screenshot of <project>'>,
    },

*/

import restaurantReservationSystemScreenshot from "./restaurant-reservation-system-screenshot.jpg"
import weatherZenScreenshot from "./weatherzen-screenshot.jpg"
import flashcardAppScreenshot from "./flashcard-app-screenshot.jpg"
import weLoveMoviesScreenshot from "./we-love-movies-screenshot.jpg"
import wildMushroomRecipesScreenshot from "./wild-mushroom-recipes-screenshot.jpg"
import workplaceWellnessScreenshot from "./workplace-wellness-screenshot.jpg"
import wealthifyaiScreenshot from "./wealthifyai-screenshot.jpg"

export const portfolio = [
    {
        id: 1,
        title: "Workplace Wellness",
        subtitle: "Sleep Health Tracker",
        description: "Web app for tracking company-wide sleep health. Developed for the Chegg Skills Winter '24 Hackathon.",
        functionality: "[Demo]",
        link: "https://workplace-wellness-2-0-4tfd.onrender.com",
        githubLink: "https://github.com/James-Lycett/workplace-wellness",
        tech: ["JavaScript", "Node.js", "React", "Tailwind", "Express.js", "PostgreSQL"],
        img: workplaceWellnessScreenshot,
        imgAlt: "Screenshot of Workplace Wellness Home Page",
    },
    {
        id: 2,
        title: "Wild-Mushroom-Recipes.com",
        subtitle: "Recipe Site",
        description: 'Food blog which allows users to find, comment on, and review recipes for wild mushrooms',
        functionality: "[Live]",
        link: "https://wild-mushroom-recipes.com",
        githubLink: null,
        tech: ["Wordpress", "Elementor", "PHP"],
        img: wildMushroomRecipesScreenshot,
        imgAlt: "Screenshot of Wild Mushroom Recipes",
    },
    {
        id: 3,
        title: "Restaurant Reservation System",
        subtitle: "Reservation Tracking App",
        description: "Web app for booking restaurant reservations and updating reservation status through entire cycle of book, cancel, seat, and finish.",
        functionality: "[Demo]",
        link: "https://restaurantreservationsystem-frontend-a4jh.onrender.com",
        githubLink: "https://github.com/James-Lycett/restaurantReservationSystem",
        tech: ["JavaScript", "Node.js", "React", "Bootstrap", "Express.js", "PostgreSQL"],
        img: restaurantReservationSystemScreenshot,
        imgAlt: "Screenshot of Restaurant Reservation System",
    },
    {
        id: 4,
        title: "WealthifyAI",
        subtitle: "AI-Powered Wealth Management App",
        description: "Designed to empower users to explore investment options and strategies. Developed for the Chegg Skills Spring '24 Hackathon.",
        functionality: "[Demo]",
        link: "https://wealthifyai.onrender.com/",
        githubLink: "https://github.com/rachel-cunningham/project-ai-investment-playground",
        tech: ["JavaScript", "Node.js", "React", "Material UI", "Express.js", "PostgreSQL", "OpenAI API"],
        img: wealthifyaiScreenshot,
        imgAlt: "Screenshot of WealthifyAI",
    },
    {
        id: 5,
        title: "We Love Movies",
        subtitle: "Movie Reviews",
        description: 'Website with some popular movies along with reviews, ratings, and theaters showing them.',
        functionality: "[Demo]",
        link: "https://welovemovies-frontend-zsml.onrender.com",
        githubLink: "https://github.com/James-Lycett/weLoveMovies-frontend",
        tech: ["JavaScript", "Node.js", "React", "Express.js", "Bootstrap"],
        img: weLoveMoviesScreenshot,
        imgAlt: "Screenshot of We Love Movies",
    },
    {
        id: 6,
        title: "WeatherZen",
        subtitle: "Weather Tracking App",
        description: "Web app for recording weather observations including sky condition, air temperature, latitude, and longitude.",
        functionality: "[Demo]",
        link: "https://weatherzen-frontend.onrender.com",
        githubLink: "https://github.com/James-Lycett/weatherzen-back-end",
        tech: ["JavaScript", "Node.js", "React", "Bootstrap", "Express.js", "PostgreSQL"],
        img: weatherZenScreenshot,
        imgAlt: "Screenshot of WeatherZen",
    },
    {
        id: 7,
        title: "Flashcard App",
        subtitle: "Flashcard Study App",
        description: 'Web app for creating, editing, and reading flash cards with "study" tool to show one face at a time.',
        functionality: "[Demo]",
        link: "https://flashcards-weld.vercel.app/",
        githubLink: "https://github.com/James-Lycett/Flashcard-App",
        tech: ["JavaScript", "Node.js", "React", "Bootstrap", "Express.js", "PostgreSQL", "Vercel"],
        img: flashcardAppScreenshot,
        imgAlt: "Screenshot of Flashcard App",
    },
]
